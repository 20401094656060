a {
  text-decoration: none;
  color: #1976d2;
  font-weight: bolder;
}

.mui-style-date-input {
  width: 100%;
  padding: 18.5px 14px;
  font-size: 1rem;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  background: #fff;
}

.mui-style-date-input:hover {
  border-color: rgba(0, 0, 0, 0.87);
}

.mui-style-date-input:focus {
  outline: 2px solid #3f51b5;
  border-color: rgba(0, 0, 0, 0.87);
}
